<template>
  <div class="px-8 pt-7 finishedDiv custom-scroll">
    <div style="display: flex; flex-flow: column; flex: 1" v-if="!selectedAttendence">
      <div class="topBar d-flex">
        <div class="titleText">Finalizados</div>
        <v-spacer></v-spacer>
        <v-select class="rounded-lg hasBlackIcon mr-4" prepend-inner-icon="la-user" append-icon="la-angle-down"
          hide-details v-model="viewPeopleModel" :items="viewPeople" dense multiple chips return-object item-text="name"
          color="black" style="background-color: white; height: fit-content; margin-bottom: 8px;" depressed height="40px"
          outlined no-data-text="Nenhuma informação disponível">
        </v-select>
        <div class="approvedRepproved">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed color="white" v-bind="attrs" v-on="on" class="filterButtons positive"
                @click="filterApproved"><v-icon dense color="#5ADDBE">mdi-thumb-up-outline</v-icon><span class="tw-ml-2"
                  style="color: #5addbe"></span></v-btn>
            </template>
            <span v-if="!this.isFilteredApproved">Exibir Aprovados</span>
            <span v-else>Exibir Todos</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed color="white" v-bind="attrs" v-on="on" class="filterButtons negative tw-ml-1"
                @click="filterDisapproved"><v-icon dense color="red"
                  class="disapprovedIcon">mdi-thumb-down-outline</v-icon><span class="pl-2"
                  style="color: #ff9391"></span></v-btn>
            </template>
            <span v-if="!this.isFilteredDisapproved">Exibir Reprovados</span>
            <span v-else>Exibir Todos</span>
          </v-tooltip>
        </div>
        <v-spacer></v-spacer>
        <div class="search">
          <v-text-field outlined placeholder="Pesquisar em finalizados" append-icon="mdi-magnify"
            v-model="findFinished"></v-text-field>
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex datePickerDiv">
          <v-menu v-model="startTimeMenu" :close-on-content-click="false" transition="slide-y-transition"
            max-width="290px" min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="startTimeFormatted" placeholder="Início" v-bind="attrs"
                @blur="startTime = parseDate(startTimeFormatted)" v-on="on" outlined hide-details readonly
                dense></v-text-field>
            </template>
            <v-date-picker :max="endTime" @change="changeStartTime" no-title @input="startTimeMenu = false"
              locale="pt-br"></v-date-picker>
          </v-menu>
          <hr class="mx-3" style="
              width: 20px;
              border: 1px solid black;
              align-self: center;
              background: black;
            " />
          <v-menu v-model="endTimeMenu" :close-on-content-click="false" transition="slide-y-transition" left
            max-width="290px" min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field placeholder="Final" v-model="endTimeFormatted" v-bind="attrs"
                @blur="endTime = parseDate(endTimeFormatted)" v-on="on" outlined hide-details readonly
                dense></v-text-field>
            </template>
            <v-date-picker :min="startTime" @change="changeEndTime" no-title @input="endTimeMenu = false"
              locale="pt-br"></v-date-picker>
          </v-menu>
        </div>
      </div>
      <div class="tableDiv d-flex" style="display: flex; flex-flow: column; flex: 1">
        <v-data-table :items="showedArray" :items-per-page="rowsPerSize" :headers="finishedHeaders"
          @click:row="changeSelectedAttendence" no-data-text="Nenhum resultado encontrado">
          <template v-slot:[`item.status`]="{ item }">
            <template v-if="item.status == 'A'">
              <v-icon dense color="#5ADDBE">mdi-thumb-up-outline</v-icon><span class="pl-2"
                style="color: #5addbe">Aprovado</span>
            </template>
            <template v-else>
              <v-icon dense color="red" class="disapprovedIcon">mdi-thumb-down-outline</v-icon><span class="pl-2"
                style="color: #ff9391">Reprovado</span>
            </template>
          </template>

          <template v-slot:[`item.user.name`]="{ item }">
            <div class="avatarBox">
              <UserPhoto size="32" v-for="(responsible, i) in item.responsibles" :user="responsible" :key="i" />
            </div>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ datetimeBr(item.createdAt).split(' ')[0] }}
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ datetimeBr(item.updatedAt).split(' ')[0] }}
          </template>

          <template v-slot:[`item.totalPrice`]="{ item }">
            {{
              new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(item.totalPrice)
            }}
          </template>

          <template v-slot:[`footer.page-text`]="footer">
            <div>
              {{ footer.pageStart }} - {{ footer.pageStop }} de
              {{ footer.itemsLength }}
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
    <div v-else style="max-height: -webkit-fill-available">
      <div class="d-flex">
        <div class="titleText">{{ selectedAttendence.client.name }}</div>
        <v-btn class="ml-3" color="white" depressed @click="isClientDialogOpen = true"><user-icon width="14" class="mr-2"
            height="14" />Dados do
          cliente</v-btn>
        <v-spacer></v-spacer>
        <template class="d-flex">
          <div class="avatarBox pr-2">
            <UserPhoto v-for="(user, i) in selectedAttendence.responsibles" :key="i" size="39" :user="user" />
          </div>
          <div v-if="selectedAttendence.statusId == 11" class="is-finished">
            <span> Finalizado </span>
          </div>
          <div v-else class="is-not-finished">
            <span> Não finalizado </span>
          </div>
        </template>
      </div>
      <div class="content-div">
        <div class="white-back-div">
          <v-tabs v-model="tab">
            <v-tabs-slider></v-tabs-slider>
            <v-tab v-for="item in tabItems" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in tabItems" :key="item">
              <div v-if="item == 'Atendimento'" class="pt-5">
                <div class="tab-inner-content">
                  <div class="sub-title">Itens do projeto</div>
                  <v-data-table :items="budgetArray" :headers="budgetHeaders" hide-default-footer
                    no-data-text="Nenhum resultado encontrado" calculate-widths disable-pagination>
                    <template v-slot:[`item.status`]="{ item }">
                      <template v-if="item.status == 'A'">
                        <v-icon dense color="#5ADDBE">mdi-thumb-up-outline</v-icon><span class="pl-2"
                          style="color: #5addbe">Aprovado</span>
                      </template>
                      <template v-else-if="item.status == 'R'">
                        <v-icon dense color="red" class="disapprovedIcon">mdi-thumb-down-outline</v-icon><span
                          class="pl-2" style="color: #ff9391">Reprovado</span>
                      </template>
                      <div v-else>Não avaliado</div>
                    </template>
                    <template v-slot:[`item.name`]="{ item, index }">
                      <div class="custom-col">
                        <div class="col-index">
                          {{ ('0' + (index + 1)).slice(-2) }}
                        </div>
                        <div>
                          {{ item.name }}
                        </div>
                      </div>
                    </template>
                    <template v-slot:[`item.totalPrice`]="{ item }">
                      <div class="col-center">
                        {{ currencyBr(getAttendenceItemPrice(item)) }}
                      </div>
                    </template>
                    <template v-slot:[`item.price`]="{ item }">
                      <div class="col-center">
                        {{
                          new Intl.NumberFormat('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(item.price)
                        }}
                      </div>
                    </template>
                    <template v-slot:[`item.notes`]="{ item }">
                      <div class="col-center">
                        <v-badge bordered overlap dot color="#31cfff" :value="isBadgeAppearing(item.notes)">
                          <div @click="chatClick(item.id)">
                            <message-circle width="25" height="25" />
                          </div>
                        </v-badge>
                      </div>
                    </template>
                    <template v-slot:[`item.discount`]="{ item }">
                      <div class="col-center">
                        {{ item.discount }}
                      </div>
                    </template>
                    <template v-slot:[`item.IPI`]="{ item }">
                      <div class="col-center">
                        {{ item.IPI }}
                      </div>
                    </template>
                  </v-data-table>
                  <div class="sub-title">Pagamento</div>
                  <v-data-table class="portions-table" hide-default-footer calculate-widths
                    no-data-text="Nenhum resultado encontrado" disable-pagination :headers="portionsHeader"
                    :items="portionsArray" item-key="id">
                    <template v-slot:[`item.expiringDate`]="{ item }">
                      <div>
                        {{ datetimeBr(item.expiringDate).split(' ')[0] }}
                      </div>
                    </template>
                    <template v-slot:[`item.price`]="{ item }">
                      <div>
                        {{ currencyBr(item.price) }}
                      </div>
                    </template>
                    <template v-slot:[`item.paymentMethod`]="{ item }">
                      <div class="d-flex" style="justify-content: center">
                        <v-select dense outlined hide-details :items="paymentMethods" :value="item.paymentMethod"
                          @input="changePaymentMethod($event, item)"></v-select>
                      </div>
                    </template>
                  </v-data-table>
                  <div class="pt-0 pb-4" style="display: flex; justify-content: center">
                    <span class="black--text" style="font-weight: 600">Total {{ currencyBr(getTotalAttendencePrice)
                    }}</span>
                  </div>
                  <div class="sub-title">Comentários</div>
                  <div class="comment-box">
                    <div class="comment" v-for="(comment, i) in commentsArray" :key="i">
                      <div class="pa-3">
                        <div class="comment-title d-flex">
                          <div class="pr-1" style="font-weight: 600">
                            aaaaaaaaa
                          </div>
                          ({{ datetimeBr(comment.createdAt) }}) disse:
                        </div>
                        <div class="comment-text">{{ comment.content }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="item == 'Histórico'" class="pt-5">
                <div class="tab-inner-content">
                  <v-data-table hide-default-footer :headers="historicHeader" no-data-text="Nenhum resultado encontrado"
                    :items="historicArray" disable-sort>
                    <template v-for="head in historicHeader" v-slot:[`header.${head.value}`]="{ header }">
                      <div class="black--text d-flex" :key="head.value" style="justify-content: center">
                        {{ header.text }}
                      </div>
                    </template>
                    <!-- <template v-slot:[`item.client`]="{ item }">
                  <div
                    class="black--text d-flex"
                    style="justify-content: center"
                  >
                    {{ item.client }}
                  </div>
                </template> -->
                    <template v-slot:[`item.createdAt`]="{ item }">
                      <div class="black--text d-flex" style="justify-content: center">
                        {{ datetimeBr(item.createdAt) }}
                      </div>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                      <div class="black--text d-flex" style="justify-content: center">
                        {{ item.description }}
                      </div>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
        <item-notes v-if="isNotesDialogOpen" @closeNotes="isNotesDialogOpen = false" v-model="isNotesDialogOpen"
          :attendence="selectedAttendence" :itemId="selectedItemId" :editable="false" />
        <client-info @changeAttendenceClicked="selectedAttendence = $event" :clientObj="selectedAttendence.client"
          v-if="isClientDialogOpen" @close="isClientDialogOpen = false" v-model="isClientDialogOpen"
          :onlyInfo="true"></client-info>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ItemNotes from '../../attendences/modals/ItemNotes.vue';
import ClientInfo from '../../clients/modals/ClientInfo.vue';
import UserPhoto from '../../../components/utils/UserPhoto.vue';

const { mapState, mapGetters, mapActions } =
  createNamespacedHelpers('attendences');

const { mapState: mapStateConfig } = createNamespacedHelpers('config');

export default {
  components: { ItemNotes, ClientInfo, UserPhoto },
  data() {
    return {
      isClientDialogOpen: false,
      isNotesDialogOpen: false,
      historicHeader: [
        { text: 'Ação', value: 'description', width: '40%' },
        // { text: 'Usuário', value: 'client', width: '30%' },
        { text: 'Data e horário', value: 'createdAt', width: '30%' },
      ],
      paymentMethods: ['PIX', 'Débito', 'Crédito', 'Dinheiro'],
      budgetHeaders: [
        { text: 'Item', value: 'name', width: 300 },
        { text: 'Status', value: 'status', width: 150 },
        { text: 'Observação', value: 'notes', width: 1 },
        { text: 'Quantidade', value: 'quantity', width: '10px' },
        { text: 'Preço unitário', value: 'price', width: 1 },
        { text: 'Desc.(%)', value: 'discount', width: 1 },
        { text: 'IPI(%)', value: 'IPI', width: 1 },
        { text: 'Preço Total', value: 'totalPrice', width: 1 },
      ],
      tabItems: ['Atendimento', 'Histórico'],
      tab: null,
      isApproved: true,
      viewPeopleModel: ['Todas as pessoas'],
      startTime: null,
      startTimeFormatted: null,
      startTimeMenu: false,
      endTime: null,
      endTimeFormatted: null,
      endTimeMenu: false,
      rowsPerSize: 4,
      finishedHeaders: [
        { text: 'Nome do projeto', value: 'client.name' },
        { text: 'Data de registro', value: 'createdAt' },
        { text: 'Data de conclusão', value: 'updatedAt' },
        { text: 'Equipe', value: 'user.name' },
        { text: 'Situação', value: 'status' },
        { text: 'Valor total', value: 'totalPrice' },
      ],
      portionsHeader: [
        { text: 'Vencimento', value: 'expiringDate' },
        { text: 'Valor', value: 'price' },
        { text: 'Forma de pagamento', value: 'paymentMethod' },
      ],
      isFilteredApproved: false,
      isFilteredDisapproved: false,
      selectedAttendence: this.$route.params.selectedAttendence,
      selectedItemId: null,
      arrayWithURL: [],
      findFinished: "",
    };
  },
  async created() {
    // this.finishedAttendences = await this.getAttendenceFinished(); 
  },
  methods: {
    ...mapActions(['downloadFile', 'getAttendenceFinished']),
    async photoURLArray() {
      let url = null;
      const responsibles = [...this.selectedAttendence.responsibles];
      let array = [];
      for (const responsible of responsibles) {
        responsible;
        let obj = { ...responsible };
        if (responsible.photoKey) {
          url = await this.downloadFile({ key: responsible.photoKey });
          obj = { ...obj, url };
        } else {
          obj = {
            ...obj,
            firstLetters: this.getAbbreviatedString(responsible.name, 2),
          };
        }
        array.push(obj);
      }
      this.arrayWithURL = array;
    },
    changeSelectedAttendence(event) {
      this.selectedAttendence = event;
      this.photoURLArray();
    },
    chatClick(id) {
      this.selectedItemId = id;
      this.isNotesDialogOpen = true;
    },
    changeEndTime(evt) {
      this.endTime = evt;
    },
    changeStartTime(evt) {
      this.startTime = evt;
    },
    filterDisapproved() {
      const btnPositive = document.querySelector('.filterButtons.positive');
      if (btnPositive.classList.contains('disabled')) {
        btnPositive.classList.remove('disabled');
        this.isFilteredDisapproved = false;
        this.isFilteredApproved = false;
      } else {
        this.isFilteredDisapproved = true;
        this.isFilteredApproved = false;
        const btnNegative = document.querySelector('.filterButtons.negative');
        btnNegative.classList.remove('disabled');
        btnPositive.classList.add('disabled');
      }
      this.updateTable();
    },
    filterApproved() {
      const btnNegative = document.querySelector('.filterButtons.negative');
      if (btnNegative.classList.contains('disabled')) {
        btnNegative.classList.remove('disabled');
        this.isFilteredDisapproved = false;
        this.isFilteredApproved = false;
      } else {
        this.isFilteredDisapproved = false;
        this.isFilteredApproved = true;
        btnNegative.classList.add('disabled');
        const btnPositive = document.querySelector('.filterButtons.positive');
        btnPositive.classList.remove('disabled');
      }
      this.updateTable();
    },
    updateTable() {
      if (this.$route.path == '/finalizados' && !this.selectedAttendence) {
        const maximumRows = this.showedArray.length;
        const main = document.querySelector('.tableDiv');
        //console.log(main.offsetHeight);
        const totalHeight = main.offsetHeight - 137;
        const rowsFitting = Math.floor(totalHeight / 40);
        const totalRows = Math.min(rowsFitting, maximumRows);
        this.rowsPerSize = totalRows;
        const counterRows = document.querySelector(
          '.tableDiv .v-select__selection.v-select__selection--comma'
        );
        counterRows.innerHTML = totalRows;
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    isBadgeAppearing(note) {
      if (note != '' && note != undefined) {
        return true;
      }
      return false;
    },
  },
  computed: {
    ...mapStateConfig(['users']),
    ...mapState(['attendences', 'finishedAttendences']),
    viewPeople() {
      return ['Todas as pessoas', ...this.users];
    },
    historicArray() {
      return this.selectedAttendence.historics;
    },
    commentsArray() {
      return this.selectedAttendence.comments;
    },
    budgetArray() {
      return this.selectedAttendence.item;
    },
    portionsArray() {
      return this.selectedAttendence.payment;
    },
    getTotalAttendencePrice() {
      let price = 0;
      this.selectedAttendence.item.forEach((el) => {
        price += this.getAttendenceItemPrice(el);
      });
      return price;
    },
    showedArray() {
      let array = JSON.parse(JSON.stringify(this.finishedAttendences));
      array = array.filter((el) => el.statusId == 11);
      array = array.map((el) => {
        el.totalPrice = 0;
        el.status = 'A';
        el.item.forEach((it) => {
          el.status = 'R';
          el.totalPrice += this.getAttendenceItemPrice(it);
          if (it.status == 'A') {
            el.status = 'A';
          }
        });
        return el;
      });
      if (this.isFilteredApproved) {
        array = array.filter((el) => el.status == 'A');
      }
      if (this.isFilteredDisapproved) {
        array = array.filter((el) => el.status == 'R');
      }
      if (this.startTime) {
        array = array.filter((item) => {
          const d1 = new Date(item.updatedAt);
          const d2 = new Date(`${this.startTime}T00:00:00`);
          d1.setHours(1);
          return +d1.getTime() <= +d2.getTime() ? false : true;
        });
      }
      if (this.endTime) {
        array = array.filter((item) => {
          const d1 = new Date(item.updatedAt);
          const d2 = new Date(`${this.endTime}T23:59:59`);
          return d1.getTime() <= d2.getTime() ? true : false;
        });
      }
      if (!this.viewPeopleModel.includes('Todas as pessoas')) {
        array = array.filter(el => this.viewPeopleModel.find(i => i.id == el.user.id))
      }
      if (this.findFinished) {
        array = array.filter((el) => (
          el.client.name.toLowerCase().includes(this.findFinished.toLocaleLowerCase())
        )
        )
      }
      return array;
    },
  },
  watch: {
    '$route.path'(newVal) {
      if (newVal == '/finalizados') {
        if (!this.selectedAttendence) {
          this.updateTable();
        }
      } else {
        this.selectedAttendence = null;
      }
    },
    '$route.params.selectedAttendence'() {
      this.selectedAttendence = this.$route.params.selectedAttendence;
    },
    showedArray() {
      this.updateTable();
    },
    startTime() {
      this.startTimeFormatted = this.formatDate(this.startTime);
      this.updateTable();
    },
    endTime() {
      this.endTimeFormatted = this.formatDate(this.endTime);
      this.updateTable();
    },
  },
  mounted() {
    this.updateTable();
    var self = this;
    window.onresize = function (event) {
      self.updateTable();
    };
  },
};
</script>

<style>
.finishedDiv .v-select__selections {
  flex-wrap: initial;
}

.finishedDiv .sub-title {
  font-weight: 600;
  font-size: 18px;
  padding-top: 20px;
}

.finishedDiv .v-data-table-header span {
  color: black;
}

.finishedDiv .user-card {
  padding-left: 0px;
  background: var(--v-primary-base);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
}

.finishedDiv .comment-box {
  display: flex !important;
  justify-content: flex-start !important;
  border: 1px solid #b7c3da;
  display: -webkit-box;
  display: -ms-flexbox;
  flex-flow: column;
  align-items: center;
  border-radius: 8px;
  height: 100px;
  margin-top: 10px;
  min-height: 350px;
  flex: 1;
  overflow: auto;
}

.finishedDiv .comment {
  width: 93%;
  border: 1px solid #b7c3da;
  margin-bottom: 10px;
  border-radius: 8px;
}

.finishedDiv .comment:first-child {
  margin-top: 10px;
}

.finishedDiv .comment-title {
  color: #177dff;
  font-size: 14px;
  font-weight: 500;
}

.finishedDiv .comment-text {
  color: #575962;
}

.finishedDiv .col-index {
  border-right: 1px solid #dddddd;
  height: 100%;
  display: flex;
  align-items: center;
  min-width: 30px;
  justify-content: center;
}

.finishedDiv .custom-col {
  display: flex;
  height: 100%;
  align-items: center;
}

.finishedDiv .white-back-div {
  padding-top: 10px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 20px;
}

.finishedDiv .content-div {
  background-color: white;
  margin-top: 10px;
}

.finishedDiv .is-finished {
  height: 39px;
  width: 200px;
  background-color: #39ec6d;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  border-radius: 8px;
}

.finishedDiv .is-not-finished {
  height: 39px;
  width: 200px;
  background-color: #8f8f8f;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  border-radius: 8px;
}

.finishedDiv .avatarBox>.v-avatar:not(:first-child) {
  margin-left: -10px;
}

.finishedDiv .avatarBox>.v-avatar {
  outline: 1px solid rgb(255, 255, 255);
}

.finishedDiv .avatarBox {
  display: flex;
}

.finishedDiv .topBar .datePickerDiv .v-input__control,
.finishedDiv .topBar .datePickerDiv .v-input__slot {
  height: 100%;
  max-width: 150px;
  padding: 0px !important;
  width: 120px;
  background: white;
  border-radius: 8px;
}

.finishedDiv .datePickerDiv input {
  max-height: none;
  height: 100%;
  width: 100%;
  text-align: center;
}

.finishedDiv .datePickerDiv .v-input {
  max-height: 40px;
}

.finishedDiv {
  display: flex;
  flex-flow: column;
  height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  overflow: auto;
}

.finishedDiv .topBar {
  flex: 0 1 auto;
  width: 100%;
  flex-wrap: wrap;
}

.finishedDiv .tableDiv {
  flex: 1 1 auto;
  overflow: hidden;
}

.finishedDiv .col-center {
  display: flex;
  justify-content: center;
}

.finishedDiv table th+th {
  border-left: 1px solid #dddddd;
}

.finishedDiv table td+td {
  border-left: 1px solid #dddddd;
}

.finishedDiv .tableDiv .v-icon {
  transition: transform 0.6s;
}

.finishedDiv .hasBlackIcon {
  max-width: 250px;
  height: 40px;
}

.finishedDiv .hasBlackIcon .v-icon {
  color: black !important;
}

.finishedDiv .titleText {
  font-weight: 500;
  font-size: 26px;
}

.finishedDiv .v-input__slot {
  align-items: center;
}

.finishedDiv .v-input__prepend-inner,
.finishedDiv .v-input__append-inner {
  margin: 0 !important;
  padding: 0 !important;
  align-self: center;
}

.finishedDiv .disapprovedIcon {
  transform: scale(-1, 1);
}

.finishedDiv .filterButtons {
  border: 1px solid #e8e8e8 !important;
  height: 40px !important;
}

.finishedDiv .filterButtons.disabled>span>i,
.finishedDiv .filterButtons.disabled>span>span {
  color: #cbcbcb !important;
  transition: none !important;
}

.finishedDiv .v-text-field--outlined fieldset {
  color: #e8e8e8 !important;
  transition: none !important;
}

/* Shadow Control */
.finishedDiv .menuable__content__active {
  border: solid 1px #c9c9c9 !important;
  box-shadow: none !important;
}

.finishedDiv .filterButtons>span>i {
  color: green;
}

.finishedDiv tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.finishedDiv .v-data-table {
  border: 1px solid #e9ecef;
  width: 100%;
  height: fit-content;
  margin-top: 10px;
}

.finishedDiv .v-data-table .v-data-footer__select {
  display: none;
}

.finishedDiv .v-data-table .v-data-footer {
  height: 59px;
}

.finishedDiv .v-data-table .v-data-footer__pagination {
  margin-left: auto;
  margin-right: 30px;
}

@media (max-width: 820px) {

  .finishedDiv .filterButtons,
  .finishedDiv .vdatetime {
    margin-top: 5px;
  }
}

.search .v-input__slot {
  background-color: #ffffff !important;
}

.search .v-text-field--outlined>.v-input__control>.v-input__slot {
  min-height: 40px;
}

.search .v-input__control {
  min-width: 252px;
  width: 330px;
}

.search .mdi-magnify::before {
  margin-left: 2px;
}

.finishedDiv .topBar {
  height: 50px !important;
  flex-wrap: nowrap !important;
}

.approvedRepproved .filterButtons {
  min-width: 52px !important;
}

.approvedRepproved .filterButtons .mdi-thumb-up-outline::before {
  margin-left: 5px;
}

.approvedRepproved .filterButtons .mdi-thumb-down-outline::before {
  margin-right: 5px;
}
</style>