<template>
  <v-dialog max-width="1000" v-model="show" @click:outside="closeDialog" scrollable>
    <v-card class="client-info" :loading="loading">
      <v-card-title>{{ client.name }}</v-card-title>
      <v-card-text>
        <v-tabs v-model="tab">
          <v-tabs-slider></v-tabs-slider>
          <v-tab v-for="item in tabItemsWithPermissions" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabItemsWithPermissions" :key="item">
            <div v-if="item == 'Dados'" class="pt-5">
              <ValidationObserver ref="observer">
                <v-row>
                  <v-col cols="7">
                    <v-card-subtitle class="pl-0 pb-2 pt-0"
                      >Nome</v-card-subtitle
                    >
                    <ValidationProvider rules="isNotBlank" v-slot="v">
                      <v-text-field
                        dense
                        outlined
                        hide-details="auto"
                        lazy-rules
                        :disabled="isDisabled"
                        v-model="client.name"
                      ></v-text-field>
                      <span class="form-error">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="5">
                    <v-card-subtitle class="pl-0 pb-2 pt-0"
                      >CPF</v-card-subtitle
                    >
                    <ValidationProvider rules="isCPFValid" v-slot="v">
                      <v-text-field
                        dense
                        outlined
                        hide-details="auto"
                        lazy-rules
                        :disabled="isDisabled"
                        v-model="client.CPF"
                        v-mask="'###.###.###-##'"
                      ></v-text-field>
                      <span class="form-error">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="7">
                    <v-card-subtitle class="pl-0 pb-2 pt-0"
                      >E-mail</v-card-subtitle
                    >
                    <ValidationProvider rules="isEmailValid" v-slot="v">
                      <v-text-field
                        dense
                        outlined
                        hide-details="auto"
                        lazy-rules
                        :disabled="isDisabled"
                        v-model="client.email"
                      ></v-text-field>
                      <span class="form-error">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="5">
                    <div class="d-flex">
                      <v-card-subtitle class="pl-0 pb-2 pt-0"
                        >Contato</v-card-subtitle
                      >
                      <v-spacer></v-spacer>
                      <v-checkbox
                        label="Whatsapp"
                        hide-details="auto"
                        v-model="client.hasWhats"
                        style="lineheight: normal; margin-top: 0px; padding: 0"
                      ></v-checkbox>
                    </div>
                    <ValidationProvider rules="isNotBlank|isContactValid" v-slot="v">
                      <v-text-field
                        outlined
                        dense
                        hide-details="auto"
                        no-data-text="Nenhuma informação disponível"
                        lazy-rules
                        :disabled="isDisabled"
                        v-mask="phoneMask"
                        v-model="client.contact"
                      ></v-text-field>

                      <span class="form-error">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="7">
                    <v-row>
                      <v-col cols="4">
                        <v-card-subtitle class="pl-0 pb-2 pt-0"
                          >CEP</v-card-subtitle
                        >
                        <ValidationProvider rules="isCEPValid" v-slot="v">
                          <v-text-field
                            dense
                            outlined
                            hide-details="auto"
                            lazy-rules
                            :disabled="isDisabled"
                            v-model="client.clientAddress.CEP"
                            v-mask="'##.###-###'"
                            @blur="autocompleteAddress(v.errors)"
                          ></v-text-field>
                          <span class="form-error">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="8">
                        <v-card-subtitle class="pl-0 pb-2 pt-0"
                          >Rua</v-card-subtitle
                        >
                        <ValidationProvider rules="" v-slot="v">
                          <v-text-field
                            dense
                            lazy-rules
                            :disabled="isDisabled"
                            outlined
                            hide-details="auto"
                            v-model="client.clientAddress.street"
                          ></v-text-field>
                          <span class="form-error">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="5">
                    <v-row>
                      <v-col cols="4">
                        <v-card-subtitle class="pl-0 pb-2 pt-0"
                          >N°</v-card-subtitle
                        >
                        <ValidationProvider rules="" v-slot="v">
                          <v-text-field
                            dense
                            lazy-rules
                            :disabled="isDisabled"
                            outlined
                            hide-details="auto"
                            v-model="client.clientAddress.number"
                            id="numberField"
                          ></v-text-field>
                          <span class="form-error">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="8">
                        <v-card-subtitle class="pl-0 pb-2 pt-0"
                          >Complemento</v-card-subtitle
                        >
                        <ValidationProvider rules="" v-slot="v">
                          <v-text-field
                            lazy-rules
                            :disabled="isDisabled"
                            dense
                            outlined
                            hide-details="auto"
                            v-model="client.clientAddress.complement"
                          ></v-text-field>
                          <span class="form-error">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="7">
                    <v-row>
                      <v-col cols="4">
                        <v-card-subtitle class="pl-0 pb-2 pt-0"
                          >Bairro</v-card-subtitle
                        >
                        <ValidationProvider rules="" v-slot="v">
                          <v-text-field
                            dense
                            outlined
                            hide-details="auto"
                            lazy-rules
                            :disabled="isDisabled"
                            v-model="client.clientAddress.neighbourhood"
                          ></v-text-field>
                          <span class="form-error">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="8">
                        <v-card-subtitle class="pl-0 pb-2 pt-0"
                          >Cidade</v-card-subtitle
                        >
                        <ValidationProvider rules="" v-slot="v">
                          <v-text-field
                            dense
                            outlined
                            hide-details="auto"
                            lazy-rules
                            :disabled="isDisabled"
                            v-model="client.clientAddress.city"
                          ></v-text-field>
                          <span class="form-error">{{ v.errors[0] }}</span>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="5">
                    <v-card-subtitle class="pl-0 pb-2 pt-0"
                      >Estado</v-card-subtitle
                    >
                    <ValidationProvider rules="" v-slot="v">
                      <v-text-field
                        dense
                        outlined
                        hide-details="auto"
                        lazy-rules
                        :disabled="isDisabled"
                        v-model="client.clientAddress.state"
                      ></v-text-field>
                      <span class="form-error">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </ValidationObserver>
            </div>
            <div v-if="item == 'Atendimentos'" class="pt-5">
              <v-data-table
                :items="attendencesByClient"
                :headers="attendencesHeaders"
                no-data-text="Nenhum resultado encontrado"
                hide-default-footer
                class="data-table"
                @click:row="attendenceClicked"
              >
                <template v-slot:[`item.statusId`]="{ item }">
                  {{ getAttendenceStatus(item.statusId).name }}
                </template>
                <template v-slot:[`item.createdAt`]="{ item }">
                  {{ datetimeBr(item.createdAt).split(' ')[0] }}
                </template>
                <template v-slot:[`item.updatedAt`]="{ item }">
                  {{ datetimeBr(item.updatedAt).split(' ')[0] }}
                </template>
                <template v-slot:[`item.attendencesAmount`]="{ item }">
                  {{ getTotalAttendences(item.id) }}
                </template>
                <template v-slot:[`item.totalPrice`]="{ item }">
                  {{ currencyBr(getTotalPrice(item)) }}
                </template>
                <!-- <template
                  v-for="head in attendencesHeaders"
                  v-slot:[`header.${head.value}`]="{ header }"
                >
                  <div :key="head.value">
                    <span>{{ header.text }}</span>
                  </div>
                </template> -->
              </v-data-table>
              <div class="d-flex mt-3">
                <v-btn dark depressed color="blue" :disabled="!userHasPermission('change_attendence')" @click="createNewAttendence"
                  >Novo atendimento</v-btn
                >
                <v-spacer></v-spacer>
                <div class="total-sum">
                  Total {{ currencyBr(sumAllAttendences) }}
                </div>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <template v-if="tab == 0">
          <v-btn dark depressed color="blue" :disabled="isDisabled" @click="updateClient"
            >Atualizar</v-btn
          >
        </template>
        <v-btn text color="blue" @click="closeDialog">Sair</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CLIENT_MASKS_MIXIN from '@/utils/mixins/masks.js';

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapState } = createNamespacedHelpers('attendences');

export default {
  mixins: [CLIENT_MASKS_MIXIN],
  props: { value: Boolean, clientObj: Object, onlyInfo: Boolean },
  data() {
    return {
      loading: false,
      tab: null,
      client: JSON.parse(JSON.stringify(this.clientObj)),
      attendencesHeaders: [
        {
          text: 'Cadastro',
          value: 'createdAt',
          align: 'center',
          sortable: false,
          class: 'header-text',
        },
        {
          text: 'Status',
          value: 'statusId',
          align: 'center',
          sortable: false,
          class: 'header-text',
        },
        {
          text: 'Responsáveis',
          value: 'user.name',
          align: 'center',
          sortable: false,
          class: 'header-text',
        },
        {
          text: 'Conclusão',
          value: 'updatedAt',
          align: 'center',
          sortable: false,
          class: 'header-text',
        },
        {
          text: 'Preço total',
          value: 'totalPrice',
          align: 'center',
          sortable: false,
          class: 'header-text',
        },
      ],
    };
  },
  methods: {
    async updateClient(){
      this.loading = true
      if (this.userHasPermission('change_client')) {        
        const isValid = await this.$refs.observer[0].validate()
        if (this.$refs.observer[0]._data.flags.valid) {
          this.$emit('confirmTrue', this.client);
      }
      }
    },
    async autocompleteAddress(errors){
      this.loading = true
      if (errors.length == 0) {
        const address = await this.findAddressByZipCode(this.client.clientAddress.CEP)
        this.client.clientAddress.city = address.city
        this.client.clientAddress.neighbourhood = address.neighborhood
        this.client.clientAddress.state = address.state
        this.client.clientAddress.street = address.street
        document.querySelector('#numberField').focus()
      }
      this.loading = false
    },
    createNewAttendence() {
      this.$router.push({
        name: 'atendimentos',
        params: { openClient: this.clientObj.id },
      });
    },
    attendenceClicked(event) {
      if (this.$route.path == '/finalizados') {
        this.$emit('changeAttendenceClicked', event);
        this.closeDialog();
      } else {
        this.$router.push({
          name: 'finalizados',
          params: { selectedAttendence: event },
        });
      }
    },
    getTotalPrice(atte) {
      let price = 0;
      atte.item.forEach((element) => {
        price += this.getAttendenceItemPrice(element)
      });
      return price;
    },
    closeDialog() {
      this.$emit('close', false);
    },
  },
  computed: {
    ...mapGetters(['getAttendencesByClient', 'getAttendenceStatus']),
    tabItemsWithPermissions(){
      return this.userHasPermission('view_attendence') ? ['Dados', 'Atendimentos'] : ['Dados'] 
    },
    isDisabled(){
      return (!this.userHasPermission('change_client') || this.onlyInfo)
    },
    phoneMask() {
      return this.getPhoneMask(this.client.contact);
    },
    attendencesByClient() {
      return this.getAttendencesByClient(this.client.id);
    },
    sumAllAttendences() {
      let sum = 0;
      this.attendencesByClient.forEach((el) => (sum += this.getTotalPrice(el)));
      return sum;
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style>
.client-info .mdi-checkbox-marked::before {
  font-weight: 900;
  content: url('https://api.iconify.design/mdi-checkbox-marked-circle.svg?color=%23007aff&height=24');
  padding-top: 3px;
}
.client-info .mdi-checkbox-blank-outline::before {
  font-weight: 900;
  padding-top: 3px;
  content: url('https://api.iconify.design/mdi-checkbox-blank-circle-outline.svg?color=rgba(0%2C0%2C0%2C0.6)&height=24');
}


.client-info .v-data-table {
  border: 1px solid #b7c3da;
  border-radius: 8px;
}

.client-info .total-sum {
  font-weight: 600;
  color: black;
}

.client-info .v-data-table td,
.client-info .v-data-table th {
  border: 1px solid #e8e8e8;
}
</style>