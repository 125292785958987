<template>
  <v-dialog max-width="800" v-model="show" scrollable>
    <v-card class="cardNotes" :loading="loading">
      <v-card-title>
        <div>
          {{ attendence.client.name }}
        </div>
        <v-spacer></v-spacer>
        <v-icon @click="$emit('closeNotes')">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-textarea
          auto-grow
          outlined
          hide-details
          class="pt-5"
          :disabled="!editable"
          v-model="notes"
          placeholder="Descreva sua observação aqui..."
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <div v-if="editable" class="d-flex" style="width: 100%">
          <v-spacer></v-spacer>
          <v-btn text depressed dark color="#177DFF" @click="$emit('closeNotes')"
            >Cancelar</v-btn
          >
          <v-btn class="rounded-lg" color="#177DFF" depressed dark>
            <div class="px-4" @click="saveNote">Salvar</div>
          </v-btn>
        </div>
        <div v-else class="d-flex" style="width: 100%">
          <v-spacer></v-spacer>
          <v-btn deppresed dark color="#177DFF" @click="$emit('closeNotes')"
            >Sair</v-btn
          >
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    attendence: Object,
    itemId: Number,
    editable: Boolean,
  },
data() {
    return {
      loading: false,
      notes: this.attendence.item.find((el) => el.id == this.itemId).notes,
    };
  },
  methods: {
    saveNote() {
      this.loading = true
      this.$emit('changeNote', this.notes);
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
